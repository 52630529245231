<template>
  <div>
    <!--Title Bar-->
    <!--<title-bar v-if="licenseSection === 1" :title-value="'Licenses'"></title-bar>-->

    <!-- Title Bar For Section List-->
    <v-card flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-id-card</v-icon>
        <v-toolbar-title class="pl-2">License</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!--Pagination  -->
    <pagination
      :show="teacherLicenseStore.teacherLicenses.data && teacherLicenseStore.teacherLicenses.data.length > 0"
      :showTotal="true"
      :currentPage="teacherLicenseStore.teacherLicenses.current_page"
      :lastPage="teacherLicenseStore.teacherLicenses.last_page"
      :total="teacherLicenseStore.teacherLicenses.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--License List-->
      <v-list
        v-if="teacherLicenseStore.teacherLicenses.data && teacherLicenseStore.teacherLicenses.data.length > 0"
        two-line
      >
        <template v-for="item in teacherLicenseStore.teacherLicenses.data">
          <v-list-item
            :key="item.id"
            @click.stop="
              openEntry(
                item.id
              )
            "
            class="my-1"
          >
            <v-list-item-content>
              <v-list-item-title class="light-blue--text text--darken-4">
              License: {{item.d_name}} &nbsp; {{item.type.d_name
              }}</v-list-item-title>
              <v-list-item-title class="light-blue--text text--darken-4">
                Experience: {{item.d_yearsExperience
              }} years</v-list-item-title>
              <v-list-item-title class="light-blue--text text--darken-4">
                Issued: {{ $_formatDate(item.d_issueDate) }} &nbsp;
                Expire: {{ $_formatDate(item.d_expirationDate)
              }}</v-list-item-title>
              <v-list-item-title
                class="text-caption grey--text"
                :class="$vuetify.theme.dark === false ? 'text--darken-1' : ''"
              >
                Created: {{ $_formatTimestamp(item.created_at) }} &#8226; Modified:
                {{ $_formatTimestamp(item.updated_at) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text
                ><span
                  class="text-body-1"
                  :class="
                    item.f_status === 1 || item.f_status === true
                      ? 'font-weight-bold green--text text--lighten-1'
                      : 'error--text'
                  "
                  >{{ $_activeStatus(item.f_status) }}</span
                >
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-list>

      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Licenses Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import { uploadHeader } from "@/env";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";

const { mapFields } = createHelpers({
  getterType: "teacherLicenseStore/getFilterField",
  mutationType: "teacherLicenseStore/updateFilterField"
});

export default {
  name: "TeacherLicenses",
  components: {
    TitleBar,
    Pagination
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      teacherLicenseStore: state => state.teacherLicenseStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "page",
      "section",

      "fk_teacherID",
      "d_name",
      "f_type",
      "d_issueDate",
      "date1",
      "d_expirationDate",
      "date2",
      "d_yearsExperience",
      "f_status",
      "f_show"
    ])
  },
  props: {
    /* All List = 1, Teacher License Sublist = 2 */
    licenseSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      filterMenu: false
    };
  },
  methods: {
    async initialize() {
      /* Grab teacher detail using uuid passed as url parameter */
      const teacher = this.$store.getters["teachersStore/getTeacher"];
      this.fk_teacherID = teacher.id;

      /* Pull teacher Licenses list */
      const filters = this.$store.getters["teacherLicenseStore/getFilters"];
      await this.$store.dispatch("teacherLicenseStore/fetch", filters);
      this.$store.dispatch("progressStore/set", false);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    async openEntry(id) {
      const vldata = {
        id: [30]
      };
      await this.$store.dispatch("valueListsStore/items", vldata);
      await this.$store.dispatch("teacherLicenseStore/entry", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
